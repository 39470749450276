import React from "react"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import SwiperTabs from '../../components/swiper-tabs'
import TwoColumn from "../../components/two-column"
import Testimonial from "../../components/testimonial"
import FreeTrial from "../../components/free-trial"
import PlanAction from '../../components/plan-action'
import CustomerJourney from '../../components/customer-journey'
import ComparisonTable from "../../components/comparison-table"
import { swiperTabsContent } from "../../lib/swiper-tabs-content"
import {
  bannerContent,
  trialContent,
  planActionContent,
  customerJourneyContent,
  discoverContent,
  businessGrowth,
  noWebsite,
  discounts,
  comparisonTableContent,
  freeAgentContent
} from "../../lib/freeagent-content"

const footerCTAContent = {
  ctaBtnUrl: "https://app.billsby.com/registration?key=2DPGO9SJDG",
  linkIsExternal: false,
}

const signUpBtnConfig = {
  url: "https://app.billsby.com/registration?key=2DPGO9SJDG",
  linkIsExternal: false
}

const FreeAgent = () => {
  return (
    <Layout
      isCreditRepairPage
      isHeaderTransparent
      footerLayout5
      footerUrlAlt={footerCTAContent.ctaBtnUrl}
      footerCTAContent={footerCTAContent}
      signUpUrlAlt={signUpBtnConfig.url}
      signUpBtnConfig={signUpBtnConfig}
    >
      <SEO
        title="Billsby | Subscription billing software for recurring payments"
        description="Rely on Billsby for easy and accurate recurring payments. The most powerful, customizable and easy-to-integrate subscription billing software."
        url="https://www.billsby.com/landing/freeagent"
      />
      <div className="free-agent">
        <TwoColumn content={bannerContent} />
        <SwiperTabs
          tabs={swiperTabsContent}
          title="If you can dream it, we can bill it."
          description="Every Billsby account supports unlimited products, plans and cycles - making it really easy to support even the most complex plans."
        />
        <TwoColumn content={discoverContent} />
        <CustomerJourney content={businessGrowth} />
        <CustomerJourney content={noWebsite} />
        <CustomerJourney content={discounts} />
        <PlanAction content={planActionContent} />
        <CustomerJourney content={customerJourneyContent} />
        <Testimonial />
        <ComparisonTable content={comparisonTableContent} />
        <CustomerJourney content={freeAgentContent} />
        <FreeTrial content={trialContent} />
      </div>
    </Layout>
  )
}

export default FreeAgent
