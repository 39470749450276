import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"

export const bannerContent = {
  title:
    "There's a reason we're the highest rated subscription billing software.",
  description: [
    {
      text:
        "The most powerful, customizable and easy to integrate subscription billing software used by hundreds of companies worldwide to simplify revenue operations.",
    },
  ],
  // withPromotion: true,
  // promotionBadge: "Offer",
  // promotionTitle: (
  //   <>
  //     <span className="bold">FreeAgent</span> customers enjoy an extended{" "}
  //     <span className="line-through">$5,000</span> $10,000 free trial
  //   </>
  // ),
  textColor: "#fff",
  backgroundColor: "#243F5C",
  svg: true,
  // image: require('../images/credit-repair.svg'),
  image: require("../images/g2-banner.svg"),
  imageName: "billsby freeagent banner image",
  imagePosition: "right",
  sectionHomeBanner: true,
  hasBg: true,
  sectionFreeAgentBanner: true,
  isBanner: true,
  buttons: [
    {
      url: "https://app.billsby.com/registration?key=2DPGO9SJDG",
      buttonText: "Sign up today",
      buttonColor: "orange",
      isExternal: false,
    },
    {
      scheduleButton: false,
      buttonText: "Schedule a demo",
      buttonColor: "white",
      url: 'https://calendly.com/initial-customer-call/1st-customer-call'
    },
  ],
  integratedBadge: "Integrated with",
  integratedBadgeImg: require("../images/freeagent.png"),
  integratedBadgeAlt: "Integrated with FreeAgent",
}

export const trialContent = {
  title: "Start growing your business today",
  description:
    <>You can sign up to Billsby for as little as $35 per month, including $15,000 per month in transaction value. <br /> The best way to get to know Billsby is to try it for yourself.</>,
  svg: true,
  image: require("../images/free-trial.svg"),
  imageName: "free-trial image",
  buttons: [
    {
      url: "https://app.billsby.com/registration?key=2DPGO9SJDG",
      buttonColor: "orange",
      buttonText: "Get started with your account today",
      isExternal: false,
    },
    {
      scheduleButton: false,
      buttonColor: "black",
      buttonText: "Learn about our pricing",
      url: "/pricing",
    },
  ],
}

export const planActionContent = {
  title: "Automate your subscriptions with Zapier.",
  description: (
    <>
      Zapier integrates Billsby with more than 2,000 other products and
      services. The result? Your customers can be automatically updated and data
      shared with the tools and systems you use.
    </>
  ),
  svg: true,
  image: require("../images/plan-action.svg"),
  imageName: "plan action image",
  cards: [
    {
      image: "plan-action-1.png",
      text: "Add the new customer to your CRM system",
      svgLogo: true,
      logo: require("../images/pipedrive.svg"),
      logoName: "pipedrive",
    },
    {
      image: "plan-action-2.png",
      text: "Subscribe them to the correct mailing list",
      svgLogo: true,
      logo: require("../images/mailchimp.svg"),
      logoName: "mailchimp",
    },
    {
      image: "plan-action-3.png",
      text: "Post out their welcome pack",
      logo: "shipwire.png",
      logoName: "shipwire",
    },
  ],
}

export const customerJourneyContent = {
  title: "Deliver a great experience throughout the customer journey.",
  list: [
    {
      title: "Get customers signed up",
      description: (
        <>
          Our{" "}
          <Link to="/product/subscribers/checkout-and-payments">
            drop-in checkout
          </Link>{" "}
          helps customers to sign up for your product quickly and easily, with
          low development effort.
        </>
      ),
    },
    {
      title: "Self-service account management",
      description: (
        <>
          Customers can manage their own account with our{" "}
          <Link to="/product/subscribers/account-management">
            drop-in account management tools
          </Link>{" "}
          - reducing service costs.
        </>
      ),
    },
    {
      title: "Keep customers from leaving",
      description: (
        <>
          When your customers think about leaving, our{" "}
          <Link to="/product/subscribers/dunning-retention">
            retention and promotion tools
          </Link>{" "}
          can stop them and help reduce churn.
        </>
      ),
    },
  ],
  svg: true,
  image: require("../images/customer-journey2.svg"),
  imageName: "customer journey",
}

export const discoverContent = {
  title:
    "Developers love Billsby because it's easier to integrate and maintain.",
  description: [
    {
      text:
        "From the start, we've built Billsby to balance the commercial desires of businesses to introduce new offers and plans frequently with developers needs to not constantly have to update their product to support these business changes. From feature tags to real-time usage counters, Billsby enables flexibility you won't find elsewhere.",
    },
  ],
  sectionDevelopers: true,
  image: require("../images/home-developers@2x.png"),
  svg: true,
  imageName: "developers svg",
  backgroundColor: "#f3f5fb",
  textColor: "#000",
  imagePosition: "right",
  discover: true,
  btnText: "Discover Billsby for developers",
}

export const businessGrowth = {
  title: "As your business grows in complexity, we'll grow with you.",
  backgroundColor: "#fff",
  list: [
    {
      title: "Support for pay-as-you-use billing",
      description: (
        <>
          Using our metered allowances, you can charge for each action, quickly
          updating data manually as things happen, or connecting with our API to
          automate this billing process. We’ll consolidate all of the charges
          and put them through for you at the end of the month.
        </>
      ),
    },
    {
      title: "Support for one-time charges",
      description: (
        <>
          If you sell additional products for a one-time fee, you can run your
          subscribers cards at any time to charge the extra. It’ll be
          automatically and immediately invoiced and taken from their account.
          No further manual action or intervention will be required on your
          part.
        </>
      ),
    },
  ],
  image: require("../images/addons-allowances.svg"),
  imageName: "billsby-business-growth",
  svg: true,
  btnClass: "adoric_video_featuretags",
}

export const noWebsite = {
  title: "No website? No problem. We've got your back.",
  backgroundColor: "#fff",
  list: [
    {
      title: "Use Billsby Pages to create beautiful landing pages",
      description: (
        <>
          Using our free page builder, you can quickly spin up a custom landing
          page where customers can both sign up and manage their account online.
          It’s really simple and only takes a few minutes - and you can change
          and update it whenever you want.
        </>
      ),
    },
    {
      title: "Or embed Billsby into your existing website",
      description: (
        <>
          Embedding the Billsby Checkout and account management tools into your
          website takes only a couple of lines of code. It’s really simple and
          we have setup guides for lots of website builders and plugins for many
          others. Plus, our success team can step in if you get stuck.
        </>
      ),
    },
  ],
  align: "right",
  svg: true,
  image: require("../images/resume-credit.svg"),
  imageName: "addons allowances",
  btnClass: "adoric_video_allowances",
}

export const discounts = {
  title: "Discounts, coupons, failed payments. All handled.",
  backgroundColor: "#fff",
  list: [
    {
      title: "Create discounts and coupons for edge case scenarios",
      description: (
        <>
          A customer wants to pay a few months in cash? No problem. Just
          generate a 100% discount code for their account for the length of
          their pre-payment. Want to run specials and offers? All fully
          supported. Billsby gives you the ultimate commercial flexibility.
        </>
      ),
    },
    {
      title: "Stop chasing failed payments",
      description: (
        <>
          When payments fail, we’ll automatically handle dunning workflows, give
          customers the opportunity to change their payment card and send out
          reminders based on the parameters you set. So you can worry less about
          the finances, and more about servicing your clients.
        </>
      ),
    },
  ],
  svg: true,
  image: require("../images/addon-allowance-1.svg"),
  imageName: "billsby-order-hierarchy",
  btnClass: "adoric_video_products",
}

export const comparisonTableContent = {
  title: "There’s cost effective, and then there’s Billsby",
  description:
    "With incredible features included for everyone – and no features you need for your business to succeed locked behind expensive plans – we’ve made sure that Billsby is the most competitive recurring payments software around.",
  heading: [
    {
      logo: "billsby_logo_blue.png",
      title: "Billsby",
    },
    {
      logo: "chargebee.png",
      title: "Chargebee",
    },
    {
      logo: "chargify.png",
      title: "Chargify",
    },
    {
      logo: "recurly.png",
      title: "Recurly",
    },
    {
      logo: "stripe-billing-logo-01.png",
      title: "Stripe Billing",
    }
  ],
  body: [
    {
      title: 'Monthly fee',
      subtitle: "base plan",
      content: [
        {
          itemContent: "$35",
        },
        {
          itemContent: "From $299",
        },
        {
          itemContent: "From $599",
        },
        {
          itemContent: "From $149",
        },
        {
          itemContent: "$0",
        },
      ]
    },
    {
      title: "Overage transaction fee",
      content: [
        {
          itemContent: "0.4%",
        },
        {
          itemContent: "Up to 0.75%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 0.9%",
        },
        {
          itemContent: "Up to 2.9% plus 30 cents",
        },
      ]
    },
    {
      title: 'Usage counters',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: "",
        },
      ]
    },
    {
      title: 'Feature tags',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: "Advanced retention tools",
      content: [
        {
          itemContent: (
            <FontAwesomeIcon icon={faCheckCircle} className="icon-check" />
          ),
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
        {
          itemContent: "",
        },
      ],
    },
    {
      title: 'Plan actions',
      subtitle: 'coming soon',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
    {
      title: 'Value scoring',
      subtitle: 'coming soon',
      content: [
        {
          itemContent: <FontAwesomeIcon icon={ faCheckCircle }  className="icon-check"/> 
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        },
        {
          itemContent: ''
        }
      ]
    },
  ],
  buttons: [
    {
      url: "https://app.billsby.com/registration?key=2DPGO9SJDG",
      buttonColor: "orange",
      text: "Sign up today",
      isExternal: true,
    },
    {
      scheduleButton: false,
      buttonColor: "black",
      url: 'https://calendly.com/initial-customer-call/1st-customer-call',
      text: "Schedule a demo",
    },
  ],
}

export const freeAgentContent = {
  title: "Now to make your accountant happy. FreeAgent is built right in.",
  list: [
    {
      title:
        "The only subscription billing platform that works perfectly with FreeAgent",
      description: (
        <>
          Our integration ensures that all of your invoices, credit notes and
          associated customer details are perfectly synchronized with FreeAgent
          in real time.
        </>
      ),
    },
    {
      title: "Payments? Forget about it",
      description: (
        <>
          When customers pay their invoice we’ll update FreeAgent too. We’ll
          even write off unpaid invoices, and remember to go back and update
          them if recovered.
        </>
      ),
    },
    {
      title: "We don’t charge extra to do the books",
      description: (
        <>
          Unlike other subscription billing companies, accounting integrations
          come as standard with our lowest price plan. It’s just the right thing
          to do.
        </>
      ),
    },
  ],
  svg: true,
  image: require("../images/accounting.svg"),
  imageName: "freeagent journey",
  extraClass: "freeagent-journey",
}
